<template>
  <div>
    <div class="contacts__header">
      Контакты
    </div>
    <div class="contacts__wrapper">
      <div class="mb3">
        ИП Кукуи Стефан Фирмин
      </div>
      <div class="mb3">
        ИНН 519098995268
      </div>
      <div class="mb3">
        ОГРН 321519000017391
      </div>
      <div class="mb3">
        pie@amphibiansrecords.ru
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contacts',
};
</script>

<style lang="scss">
  .contacts{
    &__header{
      width:27.77777777777778em;
      font-size: 1.7777777777777777em;
      padding-bottom: 1.6666666666666667em;
      border-bottom: 2px solid $whiteOpacity;
    }

    &__wrapper{
      width:27.77777777777778em;
      margin-top:2.7777777777777777em;
      padding: 2.7777777777777777em;
      border-radius: 1.9444444444444444em;
      background-color: $primaryWhite;
      font-size: 1em;
    }
  }
</style>
